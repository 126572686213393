@charset "UTF-8";
/* styling */
/* 	winst
	
	CSS-Variablen & Mixin
*/
/* Farben */
/* Abstaende */
.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important; }

/* Schrift */
/* Based on bootstrap-grid and mobile first */
/* 	 FONTS
*/
/* nunito-sans-300 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/nunito-sans-v5-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Nunito Sans Light"), local("NunitoSans-Light"), url("./fonts/nunito-sans-v5-latin-300.eot?#iefix") format("embedded-opentype"), url("./fonts/nunito-sans-v5-latin-300.woff2") format("woff2"), url("./fonts/nunito-sans-v5-latin-300.woff") format("woff"), url("./fonts/nunito-sans-v5-latin-300.ttf") format("truetype"), url("./fonts/nunito-sans-v5-latin-300.svg#NunitoSans") format("svg");
  /* Legacy iOS */ }

/* nunito-sans-regular - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/nunito-sans-v5-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Nunito Sans Regular"), local("NunitoSans-Regular"), url("./fonts/nunito-sans-v5-latin-regular.eot?#iefix") format("embedded-opentype"), url("./fonts/nunito-sans-v5-latin-regular.woff2") format("woff2"), url("./fonts/nunito-sans-v5-latin-regular.woff") format("woff"), url("./fonts/nunito-sans-v5-latin-regular.ttf") format("truetype"), url("./fonts/nunito-sans-v5-latin-regular.svg#NunitoSans") format("svg");
  /* Legacy iOS */ }

/* nunito-sans-700 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/nunito-sans-v5-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Nunito Sans Bold"), local("NunitoSans-Bold"), url("./fonts/nunito-sans-v5-latin-700.eot?#iefix") format("embedded-opentype"), url("./fonts/nunito-sans-v5-latin-700.woff2") format("woff2"), url("./fonts/nunito-sans-v5-latin-700.woff") format("woff"), url("./fonts/nunito-sans-v5-latin-700.ttf") format("truetype"), url("./fonts/nunito-sans-v5-latin-700.svg#NunitoSans") format("svg");
  /* Legacy iOS */ }

/* nunito-sans-900 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/nunito-sans-v5-latin-900.eot");
  /* IE9 Compat Modes */
  src: local("Nunito Sans Black"), local("NunitoSans-Black"), url("./fonts/nunito-sans-v5-latin-900.eot?#iefix") format("embedded-opentype"), url("./fonts/nunito-sans-v5-latin-900.woff2") format("woff2"), url("./fonts/nunito-sans-v5-latin-900.woff") format("woff"), url("./fonts/nunito-sans-v5-latin-900.ttf") format("truetype"), url("./fonts/nunito-sans-v5-latin-900.svg#NunitoSans") format("svg");
  /* Legacy iOS */ }

/* 	
	Basic-Style
*/
/* FONTS */
/* html is set to 62.5% so that all REM measurements are based on 10px sizing - so 1.5 rem = 15px; */
body {
  padding-top: 0px;
  font: 300 17px/24px Nunito Sans, sans-serif;
  font-style: normal; }

.container-fluid {
  padding-right: 0;
  padding-left: 0; }

.container-fluid .row {
   margin-left: 0;
  margin-right: 0; }

/* Helpers */
.mob-only {
  display: block; }
  @media (min-width: 960px) {
    .mob-only {
      display: none; } }

.desk-only {
  display: none; }
  @media (min-width: 960px) {
    .desk-only {
      display: block; } }

.fixed {
  position: fixed; }

.txt-right {
  text-align: right; }

.txt-left {
  text-align: left; }

.txt-center {
  text-align: center; }

.moveup:hover {
  cursor: pointer; }

.pad {
  padding-top: 30px;
  padding-bottom: 40px; }
  @media (min-width: 960px) {
    .pad {
      padding-top: 40px;
      padding-bottom: 40px; } }

.pad-top {
  padding-top: 30px; }
  @media (min-width: 960px) {
    .pad-top {
      padding-top: 40px; } }

.pad-top-large {
  padding-top: 40px; }
  @media (min-width: 960px) {
    .pad-top-large {
      padding-top: 60px; } }

.pad-bottom {
  padding-bottom: 30px; }
  @media (min-width: 960px) {
    .pad-bottom {
      padding-bottom: 40px; } }

.mar-bottom {
  margin-bottom: 40px; }
  @media (min-width: 960px) {
    .mar-bottom {
      marginn-bottom: 70px; } }

.no-pad {
  padding-left: 0;
  padding-right: 0; }

.br-on-mobile {
  display: static; }
  @media (min-width: 960px) {
    .br-on-mobile {
      display: none; } }

.nowrap {
  white-space: nowrap;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none; }

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: -3px;
    opacity: 0; }
  to {
    bottom: 0;
    opacity: 1; } }

@keyframes fadein {
  from {
    bottom: -30px;
    opacity: 0; }
  to {
    bottom: 0;
    opacity: 1; } }

/* Header */
header {
  transition: top 0.4s ease-in-out;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 40; }

.header-default {
  background: transparent; }

.header-scrolled {
  background-color: #FFFFFF;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border-bottom: 1px solid #B4C1B5; }

.nav-up {
  top: -60px; }
  @media (min-width: 960px) {
    .nav-up {
      top: -107px; } }

.header-mob-fix {
  top: 0 !important; }

main {
  padding-top: 0px; }
  @media (min-width: 960px) {
    main {
      padding-top: 0px; } }

section {
  padding: 0px 0 70px 0; }
  @media (min-width: 960px) {
    section {
      padding: 0px 0 140px 0; } }

/* Typo */
a {
  text-decoration: none;
  color: #191919;
  border-bottom: 1px solid #191919; }

a:hover {
  text-decoration: none;
  color: #7209B7;
  border-bottom: none; }

strong {
  font-weight: 700; }

h1 {
  font: 900 64px/72px Nunito Sans, sans-serif;
  padding: 35px 0;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  color: #7209B7; }

h5 {
  font: 700 17px/24px Nunito Sans, sans-serif; }

.help {
  font: 300 17px/20px Nunito Sans, sans-serif;
  color: #F0FFF1; }

/* Table */
.table thead {
  background-color: #f8f8f8; }

.table th {
  font: 700 17px/20px Nunito Sans, sans-serif;
  border-bottom: 1px solid #dee2e6 !important; }

.table td {
  font: 300 17px/20px Nunito Sans, sans-serif;
  vertical-align: baseline; }

a.icon {
  border: none; }

/* Progressbar */
ul.progressbar {
  list-style: none;
  padding: 0;
  margin: 0 0 35px 0;
  background-color: #f8f8f8; }

ul.progressbar li {
  list-style: none;
  display: inline-block;
  padding: 10px 16px 8px 16px; }

ul.progressbar li.selected {
  background-color: #FFFFFF;
  border-top: 1px solid #F0FFF1;
  border-left: 1px solid #F0FFF1;
  border-right: 1px solid #F0FFF1; }

ul.progressbar li a {
  border: none; }

ul.progressbar li a:hover {
  color: #7209B7; }

ul.progressbar li a.active {
  color: #7209B7; }

/* Dropzone/Cropper */
.dropzone-box {
  position: relative;
  clear: both; }

.kamp-sujet {
  float: left;
  max-width: 160px; }

.kamp-upload {
  width: 160px;
  height: 160px;
  padding: 0;
  margin-left: 180px; }

.dropzone {
  border: 1px dotted #666666;
  width: 160px;
  height: 160px;
  padding: 0;
  margin: 0; }

.dropzone .dz-preview {
  margin: 0; }

.dropzone .dz-preview .dz-image {
  width: 158px;
  height: 158px;
  border-radius: 0; }

.dropzone .dz-message {
  margin: 3.5em 0; }

/* FAQ */
.faq-accordeon {
  border-top: 1px solid #eaeaea; }

.faq-acc {
  border-bottom: 1px solid #eaeaea;
  padding: 16px 8px 16px 0px; }

.faq-acc-titel h4 {
  font-weight: 700;
  font-size: 1em;
  padding: 0 32px 0 0;
  margin: 0;
  background: url("/assets/img/ico_down.svg") no-repeat center right;
  background-size: 18px;
  color: #191919; }

.faq-acc-titel h4.open {
  background: url("/assets/img/ico_up.svg") no-repeat center right;
  background-size: 18px;
  color: #191919; }

.faq-acc-titel h4:hover {
  cursor: pointer; }

.faq-acc-inhalt {
  display: none; }

.faq-acc-inhalt p {
  padding: 18px 32px 8px 0px;
  margin: 0; }

/* Gradient */
.gradient {
  background: #e74645;
  background: -moz-linear-gradient(0deg, #e74645 0%, #fb7756 73%);
  background: -webkit-linear-gradient(0deg, #e74645 0%, #fb7756 73%);
  background: linear-gradient(0deg, #e74645 0%, #fb7756 73%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e74645",endColorstr="#fb7756",GradientType=1); }

/* Animations */
.shaking {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px; }

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    transform: translate3d(4px, 0, 0); } }

/* Styling fuer Navigation */
.brand {
  padding-top: 15px;
  padding-bottom: 15px; }
  @media (min-width: 960px) {
    .brand {
      padding: 28px 0 30px 0; } }

.brand a {
  border: none; }

.mobnav-open {
  display: block !important; }

nav {
  padding-top: 38px; }
  @media (min-width: 960px) {
    nav {
      padding: 24px 0 24px 0;
      position: relative; } }

nav ul {
  list-style: none;
  padding: 0;
  margin: 0; }

nav ul li {
  list-style: none;
  padding: 0 16px 0 0;
  display: inline-block; }
  @media (min-width: 1140px) {
    nav ul li {
      padding: 0 0 0 24px; } }

nav ul li a {
  font: 700 18px/18px Nunito Sans, sans-serif;
  text-decoration: none;
  border: none;
  color: #191919; }

nav ul li a:hover {
  color: #7209B7;
  cursor: pointer; }

nav ul li a.active {
  color: #191919; }

.nav-mobile-bg {
  background-color: #FFFFFF;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 2;
  display: none; }

.burger-wrapper {
  position: relative;
  padding-bottom: 16px; }

.navigation-open {
  position: absolute;
  top: 88px;
  left: 0;
  z-index: 10;
  display: block !important;
  text-align: center !important; }

.navigation-open ul {
   padding-top: 16px; }

.navigation-open ul li {
  display: block;
  text-align: center;
  padding: 16px 8px;
  position: relative; }

.navigation-open ul li a {
  color: #191919;
  font: 700 18px/18px Nunito Sans, sans-serif;
  text-transform: none;
  display: block; }

.navigation-open ul li a.active {
  color: #7209B7; }

.mob-short-info {
  text-align: left;
  padding-left: 8px;
  padding-top: 24px;
  font: 300 20px/29px Nunito Sans, sans-serif;
  color: #FFFFFF; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 28px 0 0 0;
  width: 30px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }

.hamburger:hover {
  opacity: 1; }

.hamburger.is-active:hover {
  opacity: 1; }

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #191919; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 3px;
  background-color: #191919;
  border-radius: 0px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease; }

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block; }

.hamburger-inner::before {
  top: -10px; }

.hamburger-inner::after {
  bottom: -10px; }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear; }

.hamburger--spring .hamburger-inner::before {
  top: 10px;
  width: 20px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring .hamburger-inner::after {
  top: 20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important; }

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  width: 30px;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
  background-color: #191919; }

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
  background-color: #191919; }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }

.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }

.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
   border-radius: 0px; }

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 30px;
  height: 2px; }
  @media (min-width: 720px) {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      width: 30px;
      height: 2px; } }
  @media (min-width: 1140px) {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      width: 30px;
      height: 2px; } }

.burger-wrapper button:hover {
  background-color: transparent; }

/* Styling for Footer */
footer {
  background-color: #1E241E; }

.footer-info {
  padding: 35px 0; }

footer a {
  text-decoration: none;
  color: #F0FFF1; }

footer p {
  font: 300 17px/24px Nunito Sans, sans-serif;
  color: #B4C1B5;
  padding-top: 35px;
  text-align: left; }
  @media (min-width: 960px) {
    footer p {
      text-align: left; } }

footer .navigation ul {
  list-style: none;
  padding: 16px 0 10px 0;
  margin: 0 auto; }

footer .navigation li {
  list-style: none;
  padding: 6px 0;
  text-align: center; }
  @media (min-width: 960px) {
    footer .navigation li {
      text-align: left; } }

footer .navigation li:last-child {
  border-right: none; }

footer .navigation li a {
  text-decoration: none;
  color: #B4C1B5;
  font: 400 18px/18px 17px/24px;
  border: none; }

footer .navigation li a:hover {
  color: #F0FFF1; }

.jh_instagram {
  padding-top: 24px;
  text-align: center; }
  @media (min-width: 960px) {
    .jh_instagram {
      text-align: left; } }

.legal {
  font-size: 11px;
  line-height: 13px;
  color: #191919;
  padding-top: 35px;
  text-align: center; }
  @media (min-width: 960px) {
    .legal {
      text-align: left; } }

.made-in {
  font-weight: 300;
  font-size: 0.8em;
  line-height: 2em;
  color: #B4C1B5;
  opacity: 0.7; }

.social {
  background-color: #EF2D56;
  padding: 35px 0; }

.social p {
  color: #FFFFFF;
  font-weight: 300;
  padding: 0;
  margin: 0; }

.social p strong {
  font-weight: 700; }

.social a {
  color: #FFFFFF;
  border: none; }

.social a:hover {
  color: #191919;
  text-decoration: none; }

.xperto-power {
  padding-top: 0px;
  padding-bottom: 35px;
  text-align: left; }
  @media (min-width: 960px) {
    .xperto-power {
      text-align: left; } }

/* 	
*/
/* Form */
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none; }

label {
  font: 300 17px/20px Nunito Sans, sans-serif;
  color: #B4C1B5; }
  @media (min-width: 960px) {
    label {
      font: 300 17px/20px Nunito Sans, sans-serif; } }

.anrede__wrap {
  font: 300 17px/20px Nunito Sans, sans-serif;
  padding-bottom: 16px; }
  @media (min-width: 960px) {
    .anrede__wrap {
      font: 300 17px/20px Nunito Sans, sans-serif;
      padding-bottom: 16px; } }

/* Buttons
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-block;
  height: 42px;
  padding: 0 30px;
  color: #7209B7;
  text-align: center;
  font: 400 17px/24px Nunito Sans, sans-serif;
  line-height: 42px;
  text-decoration: none;
  white-space: nowrap;
  background-color: #EF2D56;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 0px;
  cursor: pointer;
  box-sizing: border-box; }

.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.button:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
  color: #FFFFFF;
  background-color: #191919;
  outline: 0; }

.button.button-primary,
button.button-primary,
input[type="submit"].button-primary,
input[type="reset"].button-primary,
input[type="button"].button-primary {
  color: #FFFFFF;
  background-color: #7209B7;
  border-color: #7209B7;
  padding: 16px 30px;
  line-height: 1px; }

.button.button-primary:hover,
button.button-primary:hover,
input[type="submit"].button-primary:hover,
input[type="reset"].button-primary:hover,
input[type="button"].button-primary:hover,
.button.button-primary:focus,
button.button-primary:focus,
input[type="submit"].button-primary:focus,
input[type="reset"].button-primary:focus,
input[type="button"].button-primary:focus {
  color: #191919;
  background-color: #7209B7;
  border-color: #7209B7; }

.button.button-secondary,
button.button-secondary,
input[type="submit"].button-secondary,
input[type="reset"].button-secondary,
input[type="button"].button-secondary {
  color: #B4C1B5;
  background-color: #F0FFF1;
  border-color: #F0FFF1;
  padding: 0 30px;
  line-height: 1px; }

.button.button-secondary:hover,
button.button-secondary:hover,
input[type="submit"].button-secondary:hover,
input[type="reset"].button-secondary:hover,
input[type="button"].button-secondary:hover,
.button.button-secondary:focus,
button.button-secondary:focus,
input[type="submit"].button-secondary:focus,
input[type="reset"].button-secondary:focus,
input[type="button"].button-secondary:focus {
  color: #FFFFFF;
  background-color: #B4C1B5;
  border-color: #B4C1B5; }

.button.button-back,
button.button-back {
  color: #191919;
  font-weight: 700;
  background-color: #FFFFFF;
  border-color: #191919;
  border: 2px solid #191919;
  padding: 0 15px;
  margin-top: 48px;
  font-size: 14px;
  line-height: 36px; }

.button.button-back:hover,
button.button-back:hover,
.button.button-back:focus,
button.button-back:focus {
  color: #191919;
  background-color: #7209B7;
  border-color: #7209B7; }

.button.button-nav,
button.button-nav {
  color: #191919;
  font-weight: 700;
  background-color: #FFFFFF;
  border-color: #191919;
  border: 2px solid #191919;
  padding: 0 16px;
  margin-top: 0;
  margin-right: 0px;
  font-size: 18px;
  line-height: 36px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px; }

.button.button-nav:hover,
button.button-nav:hover,
.button.button-nav:focus,
button.button-nav:focus {
  color: #FFFFFF;
  background-color: #7209B7;
  border-color: #7209B7; }

::placeholder {
  /* Firefox, Chrome, Opera */
  font-weight: 400; }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: 400; }

::-ms-input-placeholder {
  /* Microsoft Edge */
  font-weight: 400; }

/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  height: 42px;
  padding: 6px 10px;
  /* The 6px vertically centers text on FF, ignored by Webkit */
  border: 1px solid #F0FFF1;
  border-radius: 1px;
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  font-size: 17px/24px;
  background-color: #F8F8F8;
  margin-bottom: 12px; }

/* Removes awkward default styles on some inputs for iOS */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

textarea {
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px; }

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  border: 1px solid #B4C1B5;
  outline: 0; }

label,
legend {
  display: block;
  margin-bottom: .2rem;
  color: #B4C1B5; }

fieldset {
  padding: 0;
  border-width: 0; }

label > .label-body {
  display: inline-block;
  margin-left: .5rem;
  font-weight: normal; }

.form-info,
.form-info-two {
  display: none;
  background-color: #E7F3FA;
  color: #007dd1;
  font-weight: 700;
  padding: 24px;
  text-align: center;
  border-radius: 0px;
  max-width: 420px;
  margin: 0 auto; }

::placeholder {
  color: #F0FFF1;
  opacity: 1;
  font-weight: 300; }

:-ms-input-placeholder {
  color: #F0FFF1;
  font-weight: 300; }

::-ms-input-placeholder {
  color: #F0FFF1;
  font-weight: 300; }

input.rad {
  border: 1px solid #191919; }

.reg_info,
.reg_footer_info,
.reg_info_err {
  display: none; }

.reg_footer_info p {
  color: #7209B7; }

.agb {
  font: 300 17px/24px Nunito Sans, sans-serif; }

.check__wrap {
  font-size: 17px/24px;
  font-weight: 700;
  padding-bottom: 35px; }

#check {
  display: inline-block;
  margin-right: 5px; }

#check:hover {
  cursor: pointer; }

input.input_error {
  border: 1px solid #7209B7 !important; }

.err_anrede,
.err_name,
.err_email,
.err_agb,
.err_betrag,
.err_minbetrag,
.err_strasse,
.err_firma,
.err_plz,
.err_branche,
.err_kanton,
.err_ort,
.err_foto,
.err_iban,
.err_code {
  color: #7209B7;
  font-size: 14px;
  font-weight: 300;
  display: none;
  margin-bottom: 10px; }

.err_anrede,
err_foto {
  margin-top: 5px; }

.dd_wrapper {
  width: 280px;
  margin: 0 auto;
  padding-top: 24px;
  position: relative; }

.dd_wrapper::before {
  font-family: Nunito Sans, sans-serif;
  content: ">";
  font-weight: 700;
  font-size: 20px;
  position: absolute;
  right: 15px;
  top: 38px;
  color: #191919;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); }

.dd_wrapper select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  width: 100%;
  height: 40px;
  padding-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #FFFFFF;
  color: #7209B7;
  font-family: Nunito Sans, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.3);
  outline: none;
  cursor: pointer; }

.dd_wrapper select::-ms-expand {
  display: none; }

.dd_wrapper select option {
  color: #191919; }

.dd_wrapper select:focus::-ms-value {
  background-color: transparent; }

.modal-content {
  border-radius: 0; }

.modal-header h5 {
  text-transform: uppercase;
  letter-spacing: 0.5px; }

.modal-header button:hover {
  background-color: transparent; }

/* 	 Launch-Site
*/
#launch {
  background-color: #191919; }

#launch .header-scrolled {
  background-color: rgba(25, 25, 25, 0.5);
  border-bottom: 0; }

#launch h1 {
  font: 900 36px/44px Nunito Sans, sans-serif;
  padding: 0;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  color: #f8f8f8; }

#launch h2 {
  font: 700 28px/38px Nunito Sans, sans-serif;
  padding: 0;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  color: #f8f8f8; }

#launch__intro {
  padding-top: 38vh;
  padding-bottom: 35px; }

#launch__register p {
  padding-bottom: 0 !important; }

#launch p.subtitle {
  font: 700 17px/20px Nunito Sans, sans-serif;
  padding: 0;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  color: #EF2D56; }

#launch p {
  font: 300 20px/29px Nunito Sans, sans-serif;
  padding: 0 0 35px 0;
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  color: #FFFFFF; }

.features {
  padding-top: 30px; }

.features_box {
  background-color: #1E241E;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 30px; }
  @media (min-width: 960px) {
    .features_box {
      min-height: 300px; } }

.features_box img {
  width: 74px;
  height: auto;
  padding-bottom: 15px;
  padding-top: 20px; }

.features_box p {
  font-size: 16px !important;
  line-height: 22px !important;
  padding: 0;
  margin: 0; }

#launch ul li {
  display: inline-block;
  padding-right: 35px; }

#launch ul li a {
  color: #B4C1B5; }

#launch p.made-in {
  font: 300 12px/16px Nunito Sans, sans-serif;
  color: #B4C1B5; }

#launch input[type="email"] {
  height: 42px;
  padding: 6px 10px;
  /* The 6px vertically centers text on FF, ignored by Webkit */
  border: 1px solid #F0FFF1;
  border-radius: 1px;
  box-shadow: none;
  box-sizing: border-box;
  min-width: 240px;
  width: 40%;
  font-size: 17px/24px;
  background-color: #191919;
  color: #F0FFF1;
  margin-bottom: 12px; }

/* Removes awkward default styles on some inputs for iOS */
#launch input[type="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

#launch input[type="email"]:focus {
  border: 1px solid #B4C1B5;
  outline: 0; }

::placeholder {
  color: #F0FFF1;
  opacity: 1;
  font-weight: 300; }

:-ms-input-placeholder {
  color: #F0FFF1;
  font-weight: 300; }

::-ms-input-placeholder {
  color: #F0FFF1;
  font-weight: 300; }

#launch input[type="submit"] {
  color: #f8f8f8; }

#launch input[type="submit"]:hover {
  background-color: #B4C1B5; }

#launch .form-info,
#launch .form-info-two {
  margin: 0;
  max-width: auto;
  text-align: left;
  color: #1E241E; }

#launch footer {
  background-color: #191919; }
