/* 	winst
	
	CSS-Variablen & Mixin
*/

/* Farben */

$black: #191919;
$white: #FFFFFF;
$grey-superlight: #f8f8f8;
$grey-light: #F0FFF1;
$grey-dark: #B4C1B5;
$grey-superdark: #1E241E;

$col-brand: #7209B7;
$col-brand-second: #EF2D56;
$col-brand-third: #FB7756;
$col-error: #EE000F;
$col-ok: #000000;

/* Abstaende */
$pad-desktop-large: 75px;
$pad-desktop-medium: 35px;
$pad-desktop-small: 10px;
$pad-mobile-large: 35px;
$pad-mobile-medium: 16px;
$pad-mobile-small: 5px;
$pad-result: 30px;
.no-padding { padding-left: 0 !important; padding-right: 0 !important; }

/* Schrift */
$brand-font: #{'Nunito Sans', sans-serif};

$txt: #{17px / 24px};
$txt-supersmall: #{12px / 16px};
$txt-small: #{17px / 20px};
$txt-medium: #{20px / 29px};
$txt-mediumlarge: #{28px / 38px};
$txt-large: #{36px / 44px};
$txt-superlarge: #{64px / 72px};

$nav-desktop: #{18px / 18px};
$nav-mobile: #{18px / 18px}; 


/* Based on bootstrap-grid and mobile first */
$small: 540px;
$medium: 720px; //i.e. Tablets portrait
$large: 960px; //i.e. tablets landscape, laptops
$extra-large: 1140px; //i.e. desktop
$xxl-large: 1460px; //i.e. large-desktop

@mixin mobile {
	@media (max-width: #{$small - 1px}) {
		@content;
	}
}

@mixin mobile-landscape {
	@media (min-width: #{$small}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$medium}) {
		@content;
	}
}

@mixin tablet-landscape {
	@media (min-width: #{$large}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$extra-large}) {
		@content;
	}
}

@mixin large-desktop {
	@media (min-width: #{$xxl-large}) {
		@content;
	}
}

