/* Styling for Footer */



footer { background-color: $grey-superdark; }
footer .container { }
.footer-info {
	padding: $pad-desktop-medium 0; }




footer a { text-decoration: none; color: $grey-light; }

footer p {
	font: 300 $txt $brand-font; 
	color: $grey-dark;
	padding-top: $pad-desktop-medium;
	text-align: left; 
	@include tablet-landscape {
		text-align: left;
		}
	}
	
	
footer .navigation ul { list-style: none; padding: $pad-mobile-medium 0 $pad-desktop-small 0; margin: 0 auto;  }
footer .navigation li { list-style: none; padding: 6px 0; text-align: center;
	@include tablet-landscape {
		text-align: left;
		}
	
}
footer .navigation li:last-child { border-right: none;}
footer .navigation li a { text-decoration: none; color: $grey-dark; font: 400 $nav-desktop $txt; border:none;}
footer .navigation li a:hover { color: $grey-light;}


.jh_instagram { padding-top: 24px;  text-align: center;
	@include tablet-landscape {
		text-align: left;
		}
}

.legal { font-size: 11px; line-height: 13px; color: $black; padding-top: $pad-desktop-medium; text-align: center; 
	@include tablet-landscape {
		text-align: left;
		}
}

.made-in {
	font-weight: 300;
	font-size: 0.8em;
	line-height: 2em;
	color: $grey-dark;
	opacity: 0.7;
}


.social {
	background-color: #EF2D56;
	padding: $pad-desktop-medium 0;
}
.social p {
	color: $white;
	font-weight: 300;
	padding: 0;
	margin: 0;
}
.social p strong { font-weight: 700; }
.social a {
	color: $white;
	border:none;
}
.social a:hover {
	color: $black;
	text-decoration: none;
}

.xperto-power	{ padding-top: 0px; padding-bottom: 35px; text-align: left;
	@include tablet-landscape {
		text-align: left;
		}
}



