/* Styling fuer Navigation */

.brand { padding-top: 15px; padding-bottom: 15px;
	@include tablet-landscape {
		padding: 28px 0 30px 0;
	}
}
.brand a {
	border: none; }


.mobnav-open	{ display: block !important; }
nav { 
	padding-top: 38px; 
	@include tablet-landscape {
		padding: 24px 0 24px 0; 
		position: relative; 
	}
}

nav ul { list-style: none; padding: 0; margin: 0; }
nav ul li { list-style: none; padding: 0 16px 0 0; display: inline-block;
	@include desktop {
		padding: 0 0 0 24px;
	}
}
nav ul li a { font: 700 $nav-desktop $brand-font; text-decoration: none; border: none; color: $black; }
nav ul li a:hover { color: $col-brand; cursor: pointer; }
nav ul li a.active { color: $black; }

.nav-mobile-bg { background-color: $white; width: 100%; height: 100vh; position: absolute; z-index: 2; display: none; }

.burger-wrapper { position: relative; padding-bottom: 16px; }



.navigation-open { position: absolute; top: 88px; left: 0; z-index: 10; display: block !important; text-align: center !important; }
.navigation-open ul { padding-top: $pad-mobile-medium; }
.navigation-open ul li { display: block; text-align: center; padding: 16px 8px; position: relative; }
.navigation-open ul li a { color: $black; font: 700 $nav-desktop $brand-font; text-transform:none; display: block; }
.navigation-open ul li a.active { color: $col-brand; }

.mob-short-info	{text-align: left; padding-left: 8px; padding-top: 24px; font: 300 $txt-medium $brand-font; color: $white; }


/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 28px 0 0 0;
  width: 30px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 1; }
  .hamburger.is-active:hover {
    opacity: 1; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: $black; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 40px;
    height: 3px;
    background-color: $black;
    border-radius: 0px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 10px;
    width: 20px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    width: 30px;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(45deg); 
    background-color: $black; }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(-45deg); 
    background-color: $black; }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }


.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after { border-radius: 0px; }

.hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 30px;
    height: 2px;
    @include tablet {
					width: 30px;
					height: 2px;
					}
				@include desktop {
					width: 30px;
					height: 2px;
					}
    }


.burger-wrapper button:hover {
	background-color: transparent; 
}