/* 	
	Basic-Style
*/


/* FONTS */
   
   
/* html is set to 62.5% so that all REM measurements are based on 10px sizing - so 1.5 rem = 15px; */   
html {

} 

body { 
	padding-top: 0px;
	font: 300 $txt $brand-font; 
	font-style: normal;
	}


		
.container-fluid { padding-right: 0; padding-left: 0; }
.container-fluid .row { margin-left: 0; margin-right: 0; }


/* Helpers */
.mob-only {
	display: block;
	@include tablet-landscape {
		display: none; 
	}
}
.desk-only {
	display:none;
	@include tablet-landscape {
		display: block;
		}
}

.fixed { position: fixed; }
.txt-right { text-align: right; }
.txt-left { text-align: left; }
.txt-center { text-align: center; }

.moveup:hover { cursor: pointer; }

.pad	{
		padding-top: 30px;
		padding-bottom: 40px;
	 @include tablet-landscape {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

.pad-top	{
		padding-top: 30px;
	 @include tablet-landscape {
		 padding-top: 40px; 
	}
}

.pad-top-large	{
		padding-top: 40px;
	 @include tablet-landscape {
		 padding-top: 60px; 
	}
}


.pad-bottom	{
		padding-bottom: 30px;
	 @include tablet-landscape {
		 padding-bottom: 40px; 
	}
}

.mar-bottom	{
		margin-bottom: 40px;
	 @include tablet-landscape {
		 marginn-bottom: 70px; 
	}
}

.no-pad { padding-left: 0; padding-right: 0; }

.br-on-mobile {
    display: static;
    @include tablet-landscape {
		display: none; 
		}	
}

.nowrap {
  white-space: nowrap;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;}
  

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: -3px; opacity: 0;} 
  to {bottom: 0; opacity: 1;}
}

@keyframes fadein {
  from {bottom: -30px; opacity: 0;}
  to {bottom: 0; opacity: 1;}
}

/* Header */

header {
  transition: top 0.4s ease-in-out; position: fixed; width: 100%; top:0; z-index: 40;
  @include tablet-landscape {
	}
}

.header-default {
	background: transparent;
}
.header-scrolled {
	background-color: $white;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
    border-bottom: 1px solid $grey-dark;
}

.nav-up {
  top: -60px; 
  @include tablet-landscape {
		top: -107px;
	}
}
.header-mob-fix {
	top: 0 !important; //mobile only, if open always show
}





main {
	padding-top: 0px;
	@include tablet-landscape {
		padding-top: 0px;
		}
}

section { 
	padding: 0px 0 70px 0;
	@include tablet-landscape {
		padding: 0px 0 140px 0;
		}
}

/* Typo */
a	{ text-decoration: none; color: $black; border-bottom: 1px solid $black;}
a:hover { text-decoration: none; color: $col-brand; border-bottom: none; }

strong {
	font-weight: 700;
}

h1 {
	font: 900 $txt-superlarge $brand-font;  padding: $pad-desktop-medium 0; -moz-hyphens: auto;
   -o-hyphens: auto;
   -webkit-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto; 
   color: $col-brand;}

h5 {
	font: 700 $txt $brand-font;
}

.help {
	font: 300 $txt-small $brand-font; color: $grey-light;
}

/* Table */
.table { }
.table thead { background-color: #f8f8f8; }
.table th {
	font: 700 $txt-small $brand-font; border-bottom: 1px solid #dee2e6 !important; }
.table td {
	font: 300 $txt-small $brand-font; 
	vertical-align: baseline;
	}
a.icon { border: none; }

/* Progressbar */
ul.progressbar {
	list-style: none; padding: 0; margin: 0 0 $pad-desktop-medium 0; background-color: $grey-superlight;
}
ul.progressbar li {
	list-style: none; display: inline-block; padding: 10px 16px 8px 16px; }
ul.progressbar li.selected {
	background-color: $white; border-top: 1px solid $grey-light; border-left: 1px solid $grey-light; border-right: 1px solid $grey-light;
}
ul.progressbar li a { border: none; }
ul.progressbar li a:hover {
	color: $col-brand; }
ul.progressbar li a.active {
	color: $col-brand; }
	
/* Dropzone/Cropper */
.dropzone-box	{ position: relative; clear: both; }
.kamp-sujet	{ float: left; max-width: 160px; }
.kamp-upload	{ width: 160px; height: 160px; padding: 0; margin-left: 180px; }
.dropzone	{ border: 1px dotted #666666; width: 160px; height: 160px; padding: 0; margin: 0;}
.dropzone .dz-preview	{ margin: 0; }
.dropzone .dz-preview .dz-image { width: 158px; height: 158px; border-radius: 0; }
.dropzone .dz-message	{ margin: 3.5em 0;}	
	
	
/* FAQ */

#faq-accordeon	{ }
.faq-accordeon 	{ border-top: 1px solid #eaeaea; }
.faq-acc	{ border-bottom: 1px solid #eaeaea; padding: 16px 8px 16px 0px; }
.faq-acc-titel h4 { font-weight: 700; font-size: 1em; padding: 0 32px 0 0; margin: 0; background: url('/assets/img/ico_down.svg') no-repeat center right ; background-size: 18px; color: $black; }
.faq-acc-titel h4.open { background: url('/assets/img/ico_up.svg') no-repeat center right ; background-size: 18px; color: $black; }
.faq-acc-titel h4:hover { cursor: pointer; }
.faq-acc-inhalt { display: none; }
.faq-acc-inhalt p { padding: 18px 32px 8px 0px; margin: 0; }


/* Gradient */
.gradient { 
	background: rgb(231,70,69);
background: -moz-linear-gradient(0deg, rgba(231,70,69,1) 0%, rgba(251,119,86,1) 73%);
background: -webkit-linear-gradient(0deg, rgba(231,70,69,1) 0%, rgba(251,119,86,1) 73%);
background: linear-gradient(0deg, rgba(231,70,69,1) 0%, rgba(251,119,86,1) 73%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e74645",endColorstr="#fb7756",GradientType=1);
}


 /* Animations */
.shaking {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

