/* 	 Launch-Site
*/

#launch {
	background-color: $black;
}


#launch .header-scrolled {
	background-color: rgba(25,25,25,0.5);
	border-bottom: 0;
}

#launch h1 {
	font: 900 $txt-large $brand-font;  padding: 0; -moz-hyphens: auto;
   -o-hyphens: auto;
   -webkit-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto; 
   color: $grey-superlight;}
   
 #launch h2 {
	font: 700 $txt-mediumlarge $brand-font;  padding: 0; -moz-hyphens: auto;
   -o-hyphens: auto;
   -webkit-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto; 
   color: $grey-superlight; 
 }
    
#launch__intro {
	   padding-top: 38vh;
	   padding-bottom: $pad-desktop-medium;
   }
  
 #launch__register p {
	 padding-bottom: 0 !important;
 }
 
   
 #launch p.subtitle {
	font: 700 $txt-small $brand-font;  padding: 0; -moz-hyphens: auto;
   -o-hyphens: auto;
   -webkit-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto; 
   color: $col-brand-second; 
 }
 
  #launch p {
	 font: 300 $txt-medium $brand-font;  padding: 0 0 $pad-desktop-medium 0; -moz-hyphens: auto;
   -o-hyphens: auto;
   -webkit-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto; 
   color: $white;
 }
 
 .features {
	 padding-top: 30px;
 }
 
 .features_box {
	 background-color: $grey-superdark;
	 border-radius: 4px;
	 padding: 12px;
	 margin-bottom: 30px;
	 @include tablet-landscape {
		 min-height: 300px;
	}
 }
 
  .features_box img {
	  width: 74px;
	  height: auto;
	  padding-bottom: 15px;
	  padding-top: 20px;
  }
  
   .features_box p {
	   font-size: 16px !important;
	   line-height: 22px !important;
	   padding: 0;
	   margin: 0;
   }
 
 
 #launch ul li {
	 display: inline-block;
	 padding-right: $pad-desktop-medium;
 }
 
  #launch ul li a {
	  color: $grey-dark;
  }
 
 #launch p.made-in {
	 font: 300 $txt-supersmall $brand-font;
	 color: $grey-dark;
	 }
	 
	 
	 
 #launch input[type="email"] {
  height: 42px;
  padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
  border: 1px solid $grey-light;
  border-radius: 1px;
  box-shadow: none;
  box-sizing: border-box;
  min-width: 240px; 
  width: 40%;
  font-size: $txt;
  background-color: $black; 
  color: $grey-light;
  margin-bottom: 12px;}
/* Removes awkward default styles on some inputs for iOS */
 #launch input[type="email"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

 #launch input[type="email"]:focus {
  border: 1px solid $grey-dark;
  outline: 0; }

::placeholder {
  color: $grey-light;
  opacity: 1;
  font-weight: 300;
}

:-ms-input-placeholder { 
  color: $grey-light;
   font-weight: 300;
}

::-ms-input-placeholder {
  color: $grey-light;
   font-weight: 300;
}

#launch input[type="submit"] {
	color: $grey-superlight;
}
#launch input[type="submit"]:hover {
	background-color: $grey-dark;
}

#launch .form-info,
#launch .form-info-two {
	margin: 0;
	max-width: auto;
	text-align: left;
	color: #1E241E;
}

#launch footer {
	background-color: $black;
}