/* 	
*/

/* Form */

input:focus,  //no blue border
select:focus,
textarea:focus,
button:focus {
    outline: none;
}


label { font: 300 $txt-small $brand-font; color: $grey-dark;
	@include tablet-landscape {
		font: 300 $txt-small $brand-font; 
		}
 }
 
 .anrede__wrap { font: 300 $txt-small $brand-font; padding-bottom: $pad-mobile-medium;
	@include tablet-landscape {
		font: 300 $txt-small $brand-font; padding-bottom: $pad-mobile-medium;
		}
 }

/* Buttons
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  display: inline-block;
  height: 42px;
  padding: 0 30px;
  color: $col-brand;
  text-align: center;
  font: 400 $txt $brand-font;
  line-height: 42px;
  text-decoration: none;
  white-space: nowrap;
  background-color: $col-brand_second;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border:0px;
  cursor: pointer;
  box-sizing: border-box; }
.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.button:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
  color: $white;
  background-color: $black;
  outline: 0; }
  
.button.button-primary,
button.button-primary,
input[type="submit"].button-primary,
input[type="reset"].button-primary,
input[type="button"].button-primary {
  color: $white;
  background-color: $col-brand;
  border-color: $col-brand; 
  padding: 16px 30px;
  line-height: 1px; }
.button.button-primary:hover,
button.button-primary:hover,
input[type="submit"].button-primary:hover,
input[type="reset"].button-primary:hover,
input[type="button"].button-primary:hover,
.button.button-primary:focus,
button.button-primary:focus,
input[type="submit"].button-primary:focus,
input[type="reset"].button-primary:focus,
input[type="button"].button-primary:focus {
  color: $black;
  background-color: $col-brand;
  border-color: $col-brand; }
  
 .button.button-secondary,
button.button-secondary,
input[type="submit"].button-secondary,
input[type="reset"].button-secondary,
input[type="button"].button-secondary {
  color: $grey-dark;
  background-color: $grey-light;
  border-color: $grey-light; 
  padding: 0 30px;
  line-height: 1px; }
.button.button-secondary:hover,
button.button-secondary:hover,
input[type="submit"].button-secondary:hover,
input[type="reset"].button-secondary:hover,
input[type="button"].button-secondary:hover,
.button.button-secondary:focus,
button.button-secondary:focus,
input[type="submit"].button-secondary:focus,
input[type="reset"].button-secondary:focus,
input[type="button"].button-secondary:focus {
  color: $white;
  background-color: $grey-dark;
  border-color: $grey-dark; }
  
.button.button-back,
button.button-back {
  color: $black;
  font-weight: 700;
  background-color: $white;
  border-color: $black; 
  border: 2px solid $black; 
  padding: 0 15px;
  margin-top: 48px;
  font-size: 14px;
  line-height: 36px;
 }
.button.button-back:hover,
button.button-back:hover,
.button.button-back:focus,
button.button-back:focus {
  color: $black;
  background-color: $col-brand;
  border-color: $col-brand; }
  
  
.button.button-nav,
button.button-nav {
  color: $black;
  font-weight: 700;
  background-color: $white;
  border-color: $black; 
  border: 2px solid $black; 
  padding: 0 16px;
  margin-top: 0;
  margin-right: 0px; 
  font-size: 18px;
  line-height: 36px;
  -webkit-border-radius: 24px;
-moz-border-radius: 24px;
border-radius: 24px;
 }
.button.button-nav:hover,
button.button-nav:hover,
.button.button-nav:focus,
button.button-nav:focus {
  color: $white;
  background-color: $col-brand;
  border-color: $col-brand; }
  
  
::placeholder { /* Firefox, Chrome, Opera */ 
    font-weight: 400;
} 
  
:-ms-input-placeholder { /* Internet Explorer 10-11 */ 
    font-weight: 400;
} 
  
::-ms-input-placeholder { /* Microsoft Edge */ 
   font-weight: 400;
} 


/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  height: 42px;
  padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
  border: 1px solid $grey-light;
  border-radius: 1px;
  box-shadow: none;
  box-sizing: border-box;
  width: 100%; 
  font-size: $txt;
  background-color: #F8F8F8; 
  margin-bottom: 12px;}
/* Removes awkward default styles on some inputs for iOS */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
textarea {
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px; }
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  border: 1px solid $grey-dark;
  outline: 0; }
label,
legend {
  display: block;
  margin-bottom: .2rem;
  color: $grey-dark; }
fieldset {
  padding: 0;
  border-width: 0; }
label > .label-body {
  display: inline-block;
  margin-left: .5rem;
  font-weight: normal; }

.form-info,
.form-info-two {
	display: none; 
	background-color: #E7F3FA;
	color: #007dd1; 
	font-weight: 700;
	padding: 24px;
	text-align: center;
	border-radius: 0px;
	max-width: 420px; 
	margin: 0 auto;
}

::placeholder {
  color: $grey-light;
  opacity: 1;
  font-weight: 300;
}

:-ms-input-placeholder { 
  color: $grey-light;
   font-weight: 300;
}

::-ms-input-placeholder {
  color: $grey-light;
   font-weight: 300;
}

input.rad	{ border: 1px solid $black; }


.reg_info,
.reg_footer_info,
.reg_info_err	{ display: none; }
.reg_footer_info p { color: $col-brand;}

.agb	{ font: 300 $txt $brand-font; }
.check__wrap	{ font-size: $txt; font-weight: 700; padding-bottom: $pad-desktop-medium; }
#check { display: inline-block; margin-right: 5px;}
#check:hover { cursor: pointer; }
//#check__agb	{ display: none; }

input.input_error { border: 1px solid $col-brand !important; }

.err_anrede,
.err_name,
.err_email,
.err_agb,
.err_betrag,
.err_minbetrag,
.err_strasse,
.err_firma,
.err_plz,
.err_branche,
.err_kanton,
.err_ort,
.err_foto,
.err_iban,
.err_code		{ color: $col-brand; font-size: 14px; font-weight: 300; display:none; margin-bottom: 10px; }
.err_anrede,
err_foto	{
	margin-top: 5px;
}

//DD Region
.dd_wrapper {
  width: 280px;
  margin: 0 auto;
  padding-top: 24px;
  position: relative;
}

.dd_wrapper::before {
  font-family: $brand-font;
  content: ">";
  font-weight: 700;
  font-size: 20px;
  position: absolute;
  right: 15px;
  top: 38px;
  color: $black;
   transform: rotate(90deg);
   -webkit-transform: rotate(90deg);
   -moz-transform: rotate(90deg);
   -ms-transform: rotate(90deg);
   -o-transform: rotate(90deg);
   filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.dd_wrapper select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;

  width: 100%;
  height: 40px;
  padding-left: 10px;
  padding-top: 0; 
  padding-bottom: 0;
  background-color: $white;
  color: $col-brand;
  font-family: $brand-font;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  
  box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.3);
  outline: none;
  
  cursor: pointer;
}
.dd_wrapper select::-ms-expand {
  display: none;
}

.dd_wrapper select option {
  color: $black;
}
.dd_wrapper select:focus::-ms-value {
  background-color: transparent;
}

.modal-content { border-radius: 0; }
.modal-header h5 { text-transform: uppercase; letter-spacing: 0.5px; }
.modal-header button:hover { background-color: transparent; }
